export const path = {
    HOME: '/',
    SIGNIN: '/sign-in',
    SIGNUP: '/sign-up',
    CREATE_ROOM: '/panel/room/create-room',
    LIST_ROOM: '/panel/room/list-room',
    PANEL: '/panel',
    PROFILE: '/panel/profile',
    ROOM_VISIT: '/panel/room-visit',
    CHANGE_PASS: '/panel/change-password',
    COLLABORATOR: '/panel/collaborator',
    FAVORITES: '/favorites',
}

export const text = {
    HOME_TITLE: 'Website tìm kiếm chỗ thuê ưng ý',
    HOME_DESCRIPTION: "Website tìm kiếm cho thuê phòng trọ, nhà nguyên căn, căn hộ, ở ghép nhanh, hiệu quả với 100.000+ tin đăng và 2.500.000 lượt xem mỗi tháng."
}

export const location = [
    {
        id: 'hcm',
        name: 'Phòng trọ Hồ Chí Minh',
        image: 'https://phongtro123.com/images/location_hcm.jpg'
    },
    {
        name: 'Phòng trọ Hà Nội',
        image: 'https://phongtro123.com/images/location_hn.jpg',
        id: 'hn',
    },
    {
        name: 'Phòng trọ Đà nẵng',
        image: 'https://phongtro123.com/images/location_dn.jpg',
        id: 'dn',
    },
]

export const provincesData: IStringValue[] = [
    { id: 'Thành phố Hồ Chí Minh', name: 'Thành phố Hồ Chí Minh' },    
]

export const districtsData: IStringValue[] = [
    { id: 'Tất cả', name: 'Tất cả' },
    { id: 'Quận 1', name: 'Quận 1' },
    { id: 'Quận 2', name: 'Quận 2' },
    { id: 'Quận 3', name: 'Quận 3' },
    { id: 'Quận 4', name: 'Quận 4' },
    { id: 'Quận 5', name: 'Quận 5' },
    { id: 'Quận 6', name: 'Quận 6' },
    { id: 'Quận 7', name: 'Quận 7' },
    { id: 'Quận 8', name: 'Quận 8' },
    { id: 'Quận 9', name: 'Quận 9' },
    { id: 'Quận 10', name: 'Quận 10' },
    { id: 'Quận 11', name: 'Quận 11' },
    { id: 'Quận 12', name: 'Quận 12' },
    { id: 'Quận Bình Thạnh', name: 'Quận Bình Thạnh' },
    { id: 'Quận Gò Vấp', name: 'Quận Gò Vấp' },
    { id: 'Quận Phú Nhuận', name: 'Quận Phú Nhuận' },
    { id: 'Quận Tân Bình', name: 'Quận Tân Bình' },
    { id: 'Quận Tân Phú', name: 'Quận Tân Phú' },
    { id: 'Thành phố Thủ Đức', name: 'Thành phố Thủ Đức' },
    { id: 'Quận Bình Tân', name: 'Quận Bình Tân' },
    { id: 'Huyện Bình Chánh', name: 'Huyện Bình Chánh' },
    { id: 'Huyện Cần Giờ', name: 'Huyện Cần Giờ' },
    { id: 'Huyện Củ Chi', name: 'Huyện Củ Chi' },
    { id: 'Huyện Hóc Môn', name: 'Huyện Hóc Môn' },
    { id: 'Huyện Nhà Bè', name: 'Huyện Nhà Bè' },
]

export const priceData: IStringValue[] = [
    { id: "0", name: 'Tất cả' },
    { id: "1", name: '<= 2 triệu' },
    { id: "2", name: '2 - 3 triệu' },
    { id: "3", name: '3 - 4 triệu' },
    { id: "4", name: '4 - 5 triệu' },
    { id: "5", name: '5 - 6 triệu' },
    { id: "6", name: '6 - 8 triệu' },
    { id: "7", name: '>= 8 triệu' },        
]

export const moveInTimeData: IStringValue[] = [
    { id: "1", name: 'Dọn vào ngay' },
    { id: "2", name: '3 Ngày' },
    { id: "3", name: '5 Ngày' },
    { id: "4", name: '7 Ngày' },
    { id: "5", name: '15 Ngày' },
    { id: "6", name: 'Xem xong mới chọn' },
    { id: "7", name: 'Đầu tháng sau' },
]

export const statusCustomerVisit: IStringValue[] = [
    { id: "0", name: 'chờ nhận tin' },
    { id: "1", name: 'đã nhận tin' },
    { id: "2", name: 'đã cọc' },
    { id: "3", name: 'chăm sóc thêm' },   
]

export const sortData: IStringValue[] = [
    { id: "default", name: 'Mặc định' },
    { id: "latest", name: 'Mới nhất' },    
    { id: "decrease", name: 'Giá giảm dần' },  
    { id: "ascending", name: 'Giá tăng dần' },       
]

export const statusUser: IStringValue[] = [    
    { id: "0", name: 'xác nhận mở' },
    { id: "1", name: 'khóa tài khoản' },
]

export const rolesUser: IStringValue[] = [    
    { id: "0", name: 'Admin' },
    { id: "1", name: 'Khách hàng' },
    { id: "2", name: 'Quản lý' },
    { id: "3", name: 'Cộng tác viên' },
]

export const typeRoom: IStringValue[] = [
    { id: "Phòng thường", name: 'Phòng thường' },
    { id: "Giường Nam", name: 'Giường Nam' },
    { id: "Giường Nữ", name: 'Giường Nữ' },    
    { id: "Phòng Studio", name: 'Phòng Studio' },  
    { id: "1 Phòng ngủ", name: '1 Phòng ngủ' },
    { id: "2 Phòng ngủ", name: '2 Phòng ngủ' },    
    { id: "3 Phòng ngủ", name: '3 Phòng ngủ' },  
    { id: "Suite 1", name: 'Suite 1' }, 
    { id: "Superior", name: 'Superior' },    
    { id: "Studio Special", name: 'Studio Special' },  
    { id: "Mặt bằng", name: 'Mặt bằng' }, 
    { id: "Penthouse", name: 'Penthouse' },    
    { id: "Duplex", name: 'Duplex' },  
    { id: "Sleepbox", name: 'Sleepbox' },  
    { id: "Standard", name: 'Standard' },  
]

export const gateLock: IStringValue[] = [    
    { id: "Khóa thường", name: 'Khóa thường' },
    { id: "Khóa từ", name: 'Khóa từ' },
    { id: "Khóa vân tay", name: 'Khóa vân tay' },
]

export const generalData: IStringValue[] = [    
    { id: "Chung", name: 'Chung' },
    { id: "Riêng", name: 'Riêng' },
]

export const gateClosingTime: IStringValue[] = [    
    { id: "Tự do", name: 'Tự do' },
    { id: "Qui định", name: 'Theo qui định' },
]

export const petData: IStringValue[] = [    
    { id: "Có", name: 'Có' },
    { id: "Không", name: 'Không' },
    { id: "Có kèm điều kiện", name: 'Có kèm điều kiện' },
]

export const unitData: IStringValue[] = [
    { id: "Không có", name: 'Không có' },
    { id: "Miễn phí", name: 'Miễn phí' },    
    { id: "Kwh", name: 'Kwh' },
    { id: "Người", name: 'Người' },
    { id: "Xe", name: 'Xe' },
    { id: "Phòng", name: 'Phòng' },
]
import React from 'react'
import DynamicIcon from '@/components/ui/dynamic-icon'

const Footer = () => {   
    return (
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
                <div className="sm:col-span-2">
                <a href="/" aria-label="Go home" title="Company" className="inline-flex items-center">
                    <DynamicIcon icon='FaLandmark' className="w-8 h-8 text-deep-purple-accent-400" />
                    <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">TÌM TRỌ THEO YÊU CẦU</span>
                </a>
                <div className="mt-6 lg:max-w-sm">
                    <p className="text-sm text-gray-800">
                        Website tìm kiếm cho thuê phòng trọ, nhà nguyên căn, căn hộ, ở ghép nhanh, hiệu quả với 100.000+ tin đăng và 2.500.000 lượt xem mỗi tháng
                    </p>                   
                </div>
                </div>
                <div className="space-y-2 text-sm">
                <p className="text-base font-bold tracking-wide text-gray-900">Liên hệ</p>
                <div className="flex">
                    <p className="mr-1 text-gray-800">Số điện thoại:</p>
                    <a href="tel:850-123-5021" aria-label="Our phone" title="Our phone" className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800">0974-900-698</a>
                </div>
                <div className="flex">
                    <p className="mr-1 text-gray-800">Email:</p>
                    <a href="timtroyeucau@gmail.com" aria-label="Our email" title="Our email" className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800">timtroyeucau@gmail.com</a>
                </div>
                <div className="flex">
                    <p className="mr-1 text-gray-800">Địa chỉ:</p>
                    <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer" aria-label="Our address" title="Our address" className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800">
                    Số 58 Đường 74, Phường 10, Quận 6
                    </a>
                </div>
                </div>
                <div>
                <span className="text-base font-bold tracking-wide text-gray-900">Xã hội</span>
                <div className="flex items-center mt-1 space-x-3">
                    <a href="/" className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                        <DynamicIcon icon='FaTelegram'/>
                    </a>
                    <a href="/" className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                        <DynamicIcon icon='FaInstagram'/>
                    </a>
                    <a href="/" className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                        <DynamicIcon icon='FaFacebook'/>
                    </a>
                </div>
                <p className="mt-4 text-sm text-gray-500">
                    Tìm phòng trọ theo yêu cầu hiện tại đang có mặt trên các mạng xã hội
                </p>
                </div>
            </div>
            <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                <p className="text-sm text-gray-600">
                © Copyright 2024 timtroyeucau.com. All rights reserved.
                </p>
                <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                <li>
                    <a href="/" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">F.A.Q</a>
                </li>
                <li>
                    <a href="/" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Chính sách bảo mật</a>
                </li>
                <li>
                    <a href="/" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Liên hệ với chúng tôi</a>
                </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer
